<script lang="ts" setup>
import device from '@@/bits/device'
import { awaitTransitionEnd } from '@@/bits/dom'
import { __ } from '@@/bits/intl'
import OzFocusTrap from '@@/library/v4/components/OzFocusTrap.vue'
import postMessage from '@@/native_bridge/post_message'
import { uniqueId } from 'es-toolkit/compat'
import type { ComponentPublicInstance } from 'vue'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    // Selects type of scrim. If none is selected, elements covered by the overlay will be fully visible.
    scrim?: 'modal' | 'popover' | null

    // Dark mode. This has no effect if scrim is turned off.
    darkMode?: true | false | 'auto'

    // Fade the overlay and its contents in. Turn this off if you are showing a series of modals.
    shouldFadeIn?: boolean

    // Decides if scrim is not really there and can be clicked through.
    isSpectral?: boolean

    // Determines CSS position.
    position?: 'fixed' | 'absolute'

    /**
     *  TailwindCSS z-index class.
     *
     *  - `z-modal2` is for all dialogs
     *  - `z-sidepanel` is for surface sidepanels
     *  - `z-dropdown` is for reaction dropdowns and drafts in surface
     *  - `z-modal-dropdown` is for reaction dropdowns in surface
     *  - `z-alert` is for all alerts
     *  - `z-mentions-box` is for mention box
     */
    zIndexClass?: 'z-modal2' | 'z-sidepanel' | 'z-dropdown' | 'z-modal-dropdown' | 'z-mentions-box' | 'z-alert'
  }>(),
  {
    scrim: 'modal',
    darkMode: 'auto',
    shouldFadeIn: true,
    isSpectral: false,
    position: 'fixed',
    zIndexClass: 'z-modal2',
  },
)
const emit = defineEmits(['scrim-click', 'scrim-esc'])

const mouseDownTarget = ref<EventTarget | null>(null)
const mouseUpTarget = ref<EventTarget | null>(null)
const focusTrapDescriptionId = uniqueId('focusTrap')

// We want to unset the `fade` class after the animation is finished
// so we keep the value in an internal ref.
const internalShouldFadeIn = ref(props.shouldFadeIn)

const overlayClasses = computed(() => [
  // Covers other elements on page
  props.position,
  'inset-0',
  props.zIndexClass,
  // Transitions
  { fade: internalShouldFadeIn.value },

  // Scrim
  props.scrim === 'modal' && {
    'bg-modal-overlay-light': props.darkMode === false,
    'bg-modal-overlay-dark': props.darkMode === true,
    'bg-modal-overlay-light dark:bg-modal-overlay-dark': props.darkMode === 'auto',
  },
  props.scrim === 'popover' && {
    'bg-popover-overlay-light': props.darkMode === false,
    'bg-popover-overlay-dark': props.darkMode === true,
    'bg-popover-overlay-light dark:bg-popover-overlay-dark': props.darkMode === 'auto',
  },

  // Misc
  'touch-action-manipulation',
])

// These colors are taken from the TW config.
const overlayColor = computed(() =>
  props.scrim === 'modal'
    ? props.darkMode === true
      ? 'rgba(36, 37, 39, 0.8)' // modal-overlay-dark | modal-overlay-light
      : 'rgba(0, 0, 0, 0.2)'
    : props.scrim === 'popover'
    ? props.darkMode === true
      ? 'rgba(255, 255, 255, 0.05)' // popover-overlay-dark | popover-overlay-light
      : 'rgba(0, 0, 0, 0.15)'
    : 'transparent',
)

const scrimClick = (e: MouseEvent) => {
  // Emits if and only if the mouse down and mouse up events
  // are both on the same element and is outside of the overlay.
  // This ensures click and drag between overlay does not hide overlay.
  if (mouseDownTarget.value === mouseUpTarget.value && mouseDownTarget.value === e.target) {
    /**
     * Emitted with overlay scrim is clicked. Can be used to trigger closure of drawer.
     */
    emit('scrim-click')
  }
}
/**
 * Emitted when an escape keydown event is propagated to the overlay.
 * Since focus is trapped, it is effectively a global listener.
 */
const scrimEsc = () => {
  emit('scrim-esc')
}

const mouseDown = (e: MouseEvent) => {
  mouseDownTarget.value = e.target
}

const mouseUp = (e: MouseEvent) => {
  mouseUpTarget.value = e.target
}

const root = ref<HTMLElement | ComponentPublicInstance>()
const rootEl = computed(() => (root.value instanceof HTMLElement ? root.value : root.value?.$el))

/**
 * We send native bridge overlay messages so the mobile app can show
 * an overlay on the native elements for consistency.
 */
const shouldSendNativeOverlayMessages = computed(() => {
  return device.app && props.scrim !== null
})

onMounted(async () => {
  if (shouldSendNativeOverlayMessages.value) {
    overlaysCount++
    postMessage({
      message_type: 'overlay_show',
      payload: {
        overlayColor: overlayColor.value,
      },
    })
  }
  if (rootEl.value == null) return
  await awaitTransitionEnd(rootEl.value)
  internalShouldFadeIn.value = false
})

onBeforeUnmount(() => {
  if (shouldSendNativeOverlayMessages.value) {
    overlaysCount--

    // Only send `overlay_hide` if there are no more overlays.
    // Otherwise, the app may hide the native overlay prematurely.
    if (overlaysCount === 0) {
      postMessage({
        message_type: 'overlay_hide',
      })
    }
  }
})
</script>

<script lang="ts">
// This is shared across all `OzOverlay` instances.
let overlaysCount = 0
</script>

<template>
  <OzFocusTrap
    v-if="!isSpectral"
    ref="root"
    :class="[overlayClasses, 'oz-overlay pointer-events-auto']"
    :aria-describedby="focusTrapDescriptionId"
    @click.native.self.stop.prevent="scrimClick"
    @keydown.native.esc.stop.prevent="scrimEsc"
    @mousedown.native="mouseDown"
    @mouseup.native="mouseUp"
  >
    <!--
      `self` in `@click.self` ensures that clicking on overlaid context doesn't tigger a scrimClick
      but only clicking on the overlay itself does.

      Supposing that `scrimClick` and `scrimEsc` attempt to close a modal, `stop` ensures one is closed
      at a time when multiple modals are open.
    -->
    <!-- @slot Content to be overlaid. -->

    <slot></slot>
    <p :id="focusTrapDescriptionId" class="sr-only">{{ __('Press the Escape key to exit this dialog') }}</p>
  </OzFocusTrap>
  <div v-else ref="root" :class="[overlayClasses, 'pointer-events-none']" @keydown.esc.stop.prevent="scrimEsc">
    <div :class="['contents', 'pointer-events-auto']">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
/*
  Avoid page zooming on double tap
*/
.touch-action-manipulation {
  touch-action: manipulation;
}

.fade {
  animation-name: oz-overlay-fade;
  animation-duration: 0.2s;

  /*
    Mitigates choppy animation on some browsers, e.g. Chrome on iOS
  */
  will-change: opacity;
}

@keyframes oz-overlay-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
